import styled, { css } from 'styled-components';

import { IMenuMobile } from './Header.interface';
import { variables } from 'daskapital-ds';

export const StyledHamburger = styled.div<IMenuMobile>`
  position: relative;
  right: -16px;
  vertical-align: middle;
  flex-direction: column;
  justify-content: center;
  gap: 8px;

  width: 48px;
  height: 48px;

  padding: 12px;

  color: white;
  background-color: ${variables.colors.primary50};
  cursor: pointer;

  span {
    position: relative;
    background: currentColor;
    width: 100%;
    height: 3px;
    transition: all 0.3s ease-in-out;

    &:nth-child(2) {
      width: 80%;
    }
  }

  ${({ $active }) =>
    $active &&
    css`
      gap: 0;
      span {
        &:nth-child(1) {
          transform: rotate(45deg);
        }
        &:nth-child(2) {
          top: -3px;
          width: 100%;
          transform: rotate(-45deg);
        }
      }
  `}
`

export const StyledMenu = styled.div<IMenuMobile>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;  
  max-height: 100vh;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: all 0.3s linear;

  overflow: auto;
  z-index: 10;

  & > div:first-child{
    position: absolute;
    width: 100%;
    height: 100%;  
    background-color: rgba(0,0,0,0.6);
  }
  & > div:last-child{
    display: flex;
    flex-flow: column;
    width: 100%;
    height: 100%;
    padding: 2rem 1rem;
    padding-top: 6rem;
    color: white;
    background-color: ${variables.colors.primary50};
    transform: translateX(100%);
    transition: all 0.3s linear;

    overflow: auto;
    margin: auto;
    margin-right: 0;


    img{
      position: absolute;
      top: auto !important;
      left: auto !important;
      bottom: 0 !important;
      right: 0 !important;  
      max-width: 55%;
      height: auto !important;
    }
    
    & ul{
      flex-flow: column;
    }

    & a{
      &:hover{
        color: ${variables.colors.secondary50} !important;
      }
    }
  }

  ${({ $active }) =>
    $active &&
    `
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
    
    &>div:last-child{
      transform: translateX(0%);
      transition-delay: 0s;
    }
  `}
`;

export const StyledSearch = styled.div<IMenuMobile>`
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  color: white;
  background-color: ${variables.colors.gray90};
  transition: all 0.3s linear;
  z-index: 110;

  & > div{
    display: flex;
    flex-flow: column;
    justify-content: center;
    width: 100%;
    padding: 1rem 0;
    transform: translateY(-100%);
    transition: all 0.3s linear;
  }

  ${({ $active }) =>
    $active &&
    `
    opacity:1;
    visibility: visible;
    pointer-events: all;
    
    & > div{
      transform: translateY(0%);
    }
  `}
`;

export const StyledHeader = styled.div<IMenuMobile>`
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;

  border-top: 10px solid ${variables.colors.primary50};
  z-index: 100;

  ul{
    li{
      a{
        width: 100%;
        justify-content: space-between;

        &:hover{
          color: ${variables.colors.primary50} !important;
        }
      }
    }
  }

  
  .style-dark &{
    color: white;
    background-color: ${variables.colors.gray90};
  }
  .style-light &{
    color: ${variables.colors.gray90};
    background-color: ${variables.colors.gray10};
  }

  & button{
    color: currentColor;
  }

  .header-absolute &{
    position: fixed;
    color:white;
    background-color: transparent;
    transition: background-color 0.3s linear;


    &.scrolled{
      color:white;
      background-color: ${variables.colors.gray90};
    }
  }

  ${({ $active }) =>
    $active && `
    color: ${variables.colors.gray90} !important;
    background-color: ${variables.colors.gray10} !important;
  `}
`;