import { createGlobalStyle } from 'styled-components';
import { variables } from 'daskapital-ds';

const CustomStyles = createGlobalStyle`
  body {
    font-family: var(--font-adamina);
    font-weight: 400;
    
    h6, p{
      font-family: var(--font-alternative);
    }

    .h6.text-uppercase{
      letter-spacing: 2px;
    }

    input, textarea{
      font-family: var(--font-alternative);
    }

    button{
      font-family: var(--font-adamina);
    }

    b{
      font-weight: bold;
    }
    sup{
      vertical-align: super;
    }
    sub{
      vertical-align: sub;
    }
  }  

  .wp-image{
    overflow: hidden;
  }

  .font-default{
    font-family: var(--font-adamina);
  }
  .font-alternative{
    font-family: var(--font-alternative);
  }

  & .quote-svg {
    max-width: 56px !important;
  }

  & .prefooter-img{
    min-width: 6rem;
  }
  .blocks-cover{
    & > div:nth-child(2),
    & > div:nth-child(2) > div,
    & > div:nth-child(2) > div > div{
      height: 100%;
    }
  }

  .simulator-totals{
    span{
      overflow-wrap: break-word;
    }
  }

  #site-footer{    
    & a{
      transition: all 0.3s linear;
      &:hover{
        color: ${variables.colors.secondary50} !important;
      }
    }
  }


  //content editor
  .single-postcontent{
    display: block;

    & > picture {
      margin-bottom: 2rem;
    }

    & > hr {
      margin-bottom: 2rem;
    }

    & .container {
      padding-left: 0;
      padding-right: 0;
    }

    .column {
      & > picture {
        max-width: 100% !important;
      }
    }

    & a {
      min-width: auto;
    }

    & p a,
    & li a {
      color: $secondary-color;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  //structure
  body > main{
    overflow: clip;
  }


  .style-light{
    color: var(--color-gray70);
    background-color: var(--color-gray10);
  }
  .style-dark{
    color: var(--color-white);
    background-color: var(--color-gray90);
  }

  .page-loading{
    cursor: wait !important;

    & body {
      opacity: 0.6 !important;
      pointer-events: none !important;
    }
  }


  //animations
  .elements_animated {
    position: relative;
    opacity: 0;
    pointer-events: none;

    will-change: transform, opacity;

    &.top,
    &.top-t-bottom {
      transform: translateY(-80px);

      &.in-viewport {
        transform: translateY(0px);
      }
    }
    &.bottom,
    &.bottom-t-top {
      transform: translateY(80px);

      &.in-viewport {
        transform: translateY(0px);
      }
    }
    &.left,
    &.left-t-right {
      transform: translateX(-80px);

      &.in-viewport {
        transform: translateX(0px);
      }
    }
    &.right,
    &.right-t-left {
      transform: translateX(80px);

      &.in-viewport {
        transform: translateX(0px);
      }
    }

    &.zoom-in {
      transform: scale(0.8);

      &.in-viewport {
        transform: scale(1);
      }
    }

    &.zoom-out {
      transform: scale(1.2);

      &.in-viewport {
        transform: scale(1);
      }
    }

    &.masked {
      opacity: 0;
      transition-delay: 1s;
      transition: opacity 0.175s ease;
    }


    &.in-viewport {
      opacity: 1;
    }

    transition: all 0.4s ease-in-out;
  }

  .splitted{
    white-space: pre;
    white-space: break-spaces;
    
    & .word{
      position:relative;
      display:inline-block;
      overflow:hidden;
      perspective:500px;
    
      & .char{
        display:inline-block!important;
        
        will-change:transform;
        transform-origin:0 50%;
        transition: transform .4s,opacity .4s cubic-bezier(0.130, 0.470, 0.130, 0.980); 
        transition-delay: calc(var(--char-index) * 0.02s);
      }
    }
    
    &.elements_animated:not(.in-viewport){
      & .word{
        & .char{
          opacity:0;
          transform:translateY(120%) rotateX(-25deg);
        }
      }
    }
  }

  .elements_animated_mask {
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    background-color: var(--color-third50);
    transform-origin: 100% 0;
    z-index: 15;
    transition: transform 1s ease-in-out;

    .in-viewport & {
      transform: scaleX(0);
    }
  }

  //Scrollbars
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 7px rgba(0, 0, 0, 0.3);
    background-color: var(--color-white);
  }
  
  ::-webkit-scrollbar {
    width: 7px;
    height: 2px;
    background-color: var(--color-white);
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: var(--color-primary50);
  }
    

  //Smooth scroll
  html.lenis {
    height: auto;
  }

  .lenis.lenis-smooth {
    scroll-behavior: auto;
  }

  .lenis.lenis-smooth [data-lenis-prevent] {
    overscroll-behavior: contain;
  }

  .lenis.lenis-stopped,
  .lenis.no-scroll {
    overflow: hidden;
  }

  .lenis.lenis-scrolling iframe {
    pointer-events: none;
  }
` as any;


export default CustomStyles;
