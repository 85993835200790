import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/lib/helpers/setFont.ts\",\"import\":\"Adamina\",\"arguments\":[{\"variable\":\"--font-adamina\",\"subsets\":[\"latin\"],\"weight\":[\"400\"]}],\"variableName\":\"adamina\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/lib/helpers/setFont.ts\",\"import\":\"Noto_Sans\",\"arguments\":[{\"variable\":\"--font-alternative\",\"subsets\":[\"latin\"],\"weight\":[\"400\"]}],\"variableName\":\"noto\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/common/Analytics/Analytics.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/common/Footer/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/common/Header/Header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/common/PreviewBox/PreviewBox.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/common/WebVitals/WebVitals.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GlobalContextProvider"] */ "/app/src/lib/globalContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/lib/registry.tsx");
