"use client";

import { BlocksProps, MenuProps } from "@/lib/wordpress/_types/blocksStyle";
import {
  Button,
  CloseButton,
  Column,
  Heading,
  Icon,
  RichText,
  Row,
  SocialLinks,
  variables,
} from "daskapital-ds";
import {
  StyledHamburger,
  StyledHeader,
  StyledMenu,
  StyledSearch,
} from "./Header.theme";
import { createRef, useState } from "react";

import DisplayImage from "../DisplayImage/DisplayImage";
import LanguageSwitcher from "../LanguageSwitcher/LanguageSwitcher";
import Link from "next/link";
import Logo from "../Logo/Logo";
import React from "react";
import { removeScroll } from "@/lib/helpers/scrollHelpers";
import { styled } from "styled-components";
import { useGlobalContext } from "@/lib/globalContext";
import { useSearchParams } from "next/navigation";

const StyledCell = styled.div`
  flex-grow: 1;

  @media only screen and (min-width: 1180px) { 
    flex-grow: 0;
    width: 20%;
  }
`;
const StyledNav = styled.div`
  display: none;

  @media only screen and (min-width: 1180px) { 
    display: inline-flex;
    justify-content: center;
    width: 60%;
  }
`;

const Header = () => {
  const state = useGlobalContext();
  const searchParams = useSearchParams();
  const [menuActive, setMenuActive] = useState(false);
  const [searchActive, setSearchActive] = useState(false);

  const headerOnClick = (isActive: boolean, search: boolean) => {
    removeScroll(isActive, state);

    if (search) {
      menuActive && setMenuActive(false);
      setSearchActive(isActive);
    } else {
      searchActive && setSearchActive(false);
      setMenuActive(isActive);
    }
  };

  const searchContainerRef = createRef<HTMLDivElement>();
  // clickOutSideToClose(searchContainerRef, () => headerOnClick(false, true));

  return (
    <>
      <StyledHeader id="masthead" $active={menuActive}>
        <Row wrap="nowrap" align="center" className="pt-2 pb-2">
          <StyledCell>
            <Logo />
          </StyledCell>
          <StyledNav>
            <nav>
              <ul className="d-flex">
                {state?.menus &&
                  state?.menus[`primary-menu`]?.map((item: MenuProps) => {
                    return (
                      <li key={`nav_menu_li_${item.id}`} className={"ms-6"}>
                        <Link
                          href={item.url || ""}
                          target={item.target ? item.target as string : undefined}
                          onClick={() => headerOnClick(false, false)}
                        >
                          <RichText tag={"p"}>
                            {item.title}
                          </RichText>
                        </Link>
                      </li>
                    );
                  })}
              </ul>
            </nav>
          </StyledNav>
          <StyledCell className="d-inline-flex align-items-center justify-content-end">
            <div className="align-middle d-none d-xl-inline-flex">
              <LanguageSwitcher></LanguageSwitcher>
              <Button
                variant={"secondary"}
                href={process.env.NEXT_PUBLIC_DASKPITAL_PLATFORM || "https://daskapital-platform.vercel.app/login"}
                target="_blank"
                className="align-middle ms-4"
              >
                {state?.dict?.custom?.signIn}
              </Button>
            </div>
            <Link
              href={process.env.NEXT_PUBLIC_DASKPITAL_PLATFORM || "https://daskapital-platform.vercel.app/login"}
              target="_blank"
              className="texts align-middle d-inline-flex d-xl-none"
            >
              {state?.dict?.custom?.signIn}
            </Link>
            <StyledHamburger
              className="d-inline-flex d-xl-none ms-4"
              $active={menuActive}
              onClick={() => headerOnClick(!menuActive, false)}
            >
              <span></span>
              <span></span>
            </StyledHamburger>
          </StyledCell>
        </Row>
      </StyledHeader>
      <StyledMenu
        $active={menuActive}
        data-lenis-prevent={true}

      >
        <div onClick={() => headerOnClick(false, false)}></div>
        <div>
          <ul className="d-flex">
            {state?.menus &&
              state?.menus[`mobile-menu`]?.map((item) => {
                return (
                  <li key={`nav_menu_li_${item.id}`} className="mb-5">
                    <Link
                      key={`nav_menu_li_-${item.id}`}
                      href={item.url || ""}
                      target={item.target ? item.target as string : undefined}
                      onClick={() => headerOnClick(false, false)}
                    >
                      <Heading
                        tag="span"
                        size="h3"
                      >
                        {item?.title as string}
                      </Heading>
                    </Link>
                  </li>
                );
              })}
          </ul>
          <div>
            <LanguageSwitcher></LanguageSwitcher>
          </div>

          <SocialLinks socials={state.themeOptions?.socials as any} className={"align-items-start mt-6"} />
          <DisplayImage
            alt={"menu_bg"}
            url={"/assets/components/menu_mobile.svg"}
            fill={true}
          />
        </div>
      </StyledMenu>
      {/* <StyledSearch
        ref={searchContainerRef}
        $active={searchActive}
        data-lenis-prevent={true}
      >
        <div>
          <CloseButton
            variant="sm"
            onClick={() => headerOnClick(!searchActive, true)}
          ></CloseButton>
          <SearchForm
            value={searchParams.get("q") as string}
            onClose={() => headerOnClick(!searchActive, true)}
          />
        </div>
      </StyledSearch> */}
    </>
  );
};

export default Header;
